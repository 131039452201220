import LoginPageContent from '../components/content/LoginPageContent';

export default function LoginPage() {
    return (
        <>
            <div className='wrapper'>
                <LoginPageContent />
            </div>
        </>
    );
}
