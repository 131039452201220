import ResetPasswordContent from '../components/content/ResetPasswordContent';

export default function ResetPassword() {
    return (
        <>
            <div className='wrapper'>
                <ResetPasswordContent />
            </div>
        </>
    );
}