import { useContext, useState } from 'react';
import { Image, Form, Button, Alert, Card } from 'react-bootstrap';
import { useFormik } from 'formik';
import { IResetPassword } from '../../lib/types/input/resetPassword.input';
import { UserProfileContext } from '../../contexts/UserProfile.Context';
import { IFetchError } from '../../lib/types/types';
import { handlePotentialAxiosError, storeTokenExpiryInLocalStorage, storeUserAndTokenInLocalStorage, wipeLocalStorage } from '../../lib/utilityFunctions';
import { getUserWithEmailAndPass } from '../../lib/api/userRoutes';
import { resetUserPassword } from '../../lib/api/userRoutes';
import { ROUTES } from '../../lib/constants';
export default function LoginPageContent() {
    const {
        setToken,
        setUser,
    } = useContext(UserProfileContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<IFetchError | null>(null);
    const [showError, setShowError] = useState(true);
  
  
    const submitHandler = async (values: IResetPassword) => {
        try {
        // Set loading 
            setIsLoading(true);
            await resetUserPassword(values);
            // Destructure payload and set global and local state
            const { token, user } = await getUserWithEmailAndPass(values);
            storeUserAndTokenInLocalStorage(token, user);
            storeTokenExpiryInLocalStorage();
            setToken(token);
            setUser(user);
  
            // remove previous errors
            setError(null);
            formik.resetForm();
        } catch (error) {
            const genericMessage = 'Error occured while logging in user.';
            const errorObj = handlePotentialAxiosError(genericMessage, error);
            setError(errorObj);
            wipeLocalStorage();
        } finally {
            setIsLoading(false);
        }
    };
  
    const formik = useFormik<IResetPassword>({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',

        },
        onSubmit: submitHandler
    }); 
    return (
        <main className='login-page-content'>
            <Card>
                <Card.Body className='my-5'>
                    <Image
                        className='mb-4'
                        src='/MyLivingCity_Logo_NameOnly.png'
                        fluid
                    />

                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group controlId='loginEmail' className='mt-2'>
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                name='email'
                                type='email'
                                required
                                placeholder='Enter email'
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                        </Form.Group>
                        <Form.Group controlId='loginPassword' className='mb-2'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                name='password'
                                type='password'
                                required
                                placeholder='Password'
                                onChange={formik.handleChange}
                                value={formik.values.password}
                            />
                        </Form.Group>
                        <Form.Group controlId='loginConfirmPassword' className='mb-2'>
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                name='confirmPassword'
                                type='password'
                                required
                                placeholder='Password'
                                onChange={formik.handleChange}
                                value={formik.values.confirmPassword}
                            />
                        </Form.Group>
                        {error && (
                            <Alert 
                                show={showError} 
                                onClose={() => setShowError(false)} 
                                dismissible
                                // variant='danger' 
                                variant='danger' 
                                className='error-alert' 
                            >
                                { error.message}
                            </Alert>
                        )}
                        <Button
                            block
                            type='submit'
                            disabled={isLoading ? true : false}
                        >
                Login
                        </Button>
                    </Form>
                    <div className='w-100 text-center mt-2'>
                        <a href={ROUTES.REGISTER}>Don't have an account? Create one.</a>
                    </div>
                </Card.Body>
            </Card>
        </main >
    );
}