import AdminEmailGeneratePageContent from '../components/content/AdminEmailGeneratePageContent';

export default function AdminEmailGeneratePage() {
    return (
        <div className='wrapper'>
            <AdminEmailGeneratePageContent />
        </div>
    );
  
}
