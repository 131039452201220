import React from 'react';
import CheckEmailPageContent from 'src/components/content/CheckEmailPageContent';

function CheckEmailPage() {
    return (
        <div>
            <CheckEmailPageContent />
        </div>
    );
}

export default CheckEmailPage;
